'use client';
import { CustomRequestEvent, DataLayerService } from '@/lib/data-layer/data-layer';
import { DataLayerEventCategoryEnum } from '@/lib/data-layer/types';
import { ArrowRightIcon } from '@/src/features/ui/icons/icons';
import classNames from 'classnames';
import Image from 'next/image';
import { RelLink } from '../../ui/rel-link';
import { ResponseMenuItem } from '@/src/interfaces/data-contracts';
import { ConditionalWrapper } from '@/src/utils/helpers/conditional-wrapper';
import printBadgeType from '@/src/utils/helpers/badge-type';

const dataLayer = new DataLayerService();
export const ListItem: React.FC<ResponseMenuItem & { eventAction?: string | '' }> = (props) => {
  const handleClickEvent = (eventLabel: string) => {
    dataLayer.sendEvent(
      new CustomRequestEvent(eventLabel, props.eventAction, DataLayerEventCategoryEnum.WIDGET_CLICK, '-')
    );
  };
  const badgeStyle =
    'absolute right-0 top-0 py-1 px-[6px] rounded-tl-none rounded-tr-xl rounded-bl-xl rounded-br-none font-semibold text-xs';

  return (
    <button
      onClick={() => handleClickEvent(props.name ?? 'menu item has no name')}
      key={props.id}
      className={classNames('relative flex flex-row rounded-lg bg-white p-4 md:p-5', {
        'pointer-events-none': 30 === (props.badgeType as unknown as number),
      })}
    >
      {props.badgeType && (
        <span
          className={classNames(
            { 'bg-introLight text-intro': 10 === (props.badgeType as unknown as number) },
            {
              'bg-purple50 text-purple404': 20 === (props.badgeType as unknown as number),
            },
            {
              'bg-orange50 text-orange500': 30 === (props.badgeType as unknown as number),
            },
            badgeStyle
          )}
        >
          {printBadgeType[props.badgeType]}
        </span>
      )}
      <ConditionalWrapper
        condition={props.url}
        wrapper={(children) => (
          <RelLink title={props.name ?? ''} href={props.url!} className="flex w-full flex-row justify-between">
            {children}
          </RelLink>
        )}
      >
        <div className="flex w-full flex-col gap-2 md:gap-4">
          <div className="relative h-12 w-12 md:h-10 md:w-10">
            {props.imagePath && (
              <Image
                decoding="async"
                loading="lazy"
                src={props.imagePath}
                alt={props.name ?? 'menu item has no name'}
                fill
              />
            )}
          </div>
          <div
            className={classNames('flex justify-between text-sm font-semibold text-black1000 md:text-base', {
              ['opacity-50']: (props.badgeType as unknown as number) === 30,
            })}
          >
            <div>{props.name}</div>
            <ArrowRightIcon className="stroke-[#4a49bb] stroke-[2.5px]" width={24} height={24} />
          </div>
        </div>
      </ConditionalWrapper>
    </button>
  );
};
